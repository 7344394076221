<template>
<!-- main -->
<main id="container">
    <!-- 메인비쥬얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <p class="desc_section">보험클리닉 내방형 보험샵</p>
            <h3 class="tit_section">신입/경력 <br>상담매니저 공개채용</h3>
            <p class="receipt_time">접수기간 : 상시채용</p>
            <img class="book" src="/img/recruit/bohum/visual_book.png?v=230327" alt="보험클리닉 처방전">
            <button class="icon-scroll anchor" data-target="#interview" type="button"></button>
        </div>
    </section>
    <!-- //메인비쥬얼 -->

    <!-- 인터뷰
    <section id="interview" class="section">
        <div class="inner">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p class="tit">보험클리닉 이수점</p>
                        <p class="desc">고객의 불편함을 보듬어주고 또 해결하기 위해 노력하다 보면 제가 그 고객의 또 다른<br> 동반자로서의 역할을 하게 될 것이라고 생각해요. 그런 날이 올 수 있도록 최선을 다할<br> 것이고요. 또 일과 가정을 리드하는 커리어우먼으로서 당당하게 제 꿈을 펼치고 싶어요.</p>
                        <p class="name">안은지 상담매니저</p>
                        <img class="photo" src="/img/recruit/bohum/interview_slide_bg1.png" alt="사진" >
                    </div>
                    <div class="swiper-slide">
                        <p class="tit">보험클리닉 탄방점</p>
                        <p class="desc">고객들께서 가까운 지인이나 전화로 보험을 가입하지만 정작 고민이 있거나 상담이 필요할 때<br> 상담할 수 있는 곳이 없다 보니 보험클리닉에 오셔서 필요한 부분을 요청하거나<br> 고민을 이야기하고, 고맙다고 하실 때 일에 대한 보람을 느끼고 있습니다.</p>
                        <p class="name">홍지현 상담매니저</p>
                        <img class="photo" src="/img/recruit/bohum/interview_slide_bg2.png" alt="사진" >
                    </div>
                    <div class="swiper-slide">
                        <p class="tit">보험클리닉 해운대점</p>
                        <p class="desc">보험설계사 아닌 상담 매니저이기 때문에 보험설계사들이 상담하면서 미처 말하지 못하는<br> 부분도 고객의 입장에서 객관적으로 말해줄 수 있어, 고객들이 더 신뢰하고<br> 좋아해 줄 때 많은 보람을 느끼고 있습니다.</p>
                        <p class="name">허미경 상담매니저</p>
                        <img class="photo" src="/img/recruit/bohum/interview_slide_bg3.png" alt="사진" >
                    </div>
                </div>                
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
    //인터뷰 -->

    <!-- 상담매니저 이야기 -->
    <!-- <section id="bohumStory" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 상담매니저에 지원할 수 밖에 없는 이유</h3>
            <p class="desc_section">영상으로 확인하고 지금 바로 지원하세요!</p>
            <ul class="list_video">
                <li class="item_video">
                    <p class="tit">상담매니저의 하루편</p>
                    <p class="tag">#상담매니저의 하루는 어떨까요? #수고했어 오늘도 </p>
                    <button data-src="https://www.youtube.com/embed/UhCVSTTaJ1U" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/UhCVSTTaJ1U/maxresdefault.jpg" alt="스크린샷">
					</button>
                </li>
                <li class="item_video">
                    <p class="tit">워라밸편</p>
                    <p class="tag">#눈치게임 없는 퇴근시간  #일과 삶의 균형</p>
                    <button data-src="https://www.youtube.com/embed/gJOjLefLRXA" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/gJOjLefLRXA/maxresdefault.jpg" alt="스크린샷">
					</button>
                </li>
                <li class="item_video">
                    <p class="tit">영업지원편</p>
                    <p class="tag">#고객이 직접 매장으로 방문해주시니까</p>
                    <button data-src="https://www.youtube.com/embed/GCY6upao2dc" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/GCY6upao2dc/maxresdefault.jpg" alt="스크린샷">
                    </button>
                </li>
                <li class="item_video">
                    <p class="tit">마~ 이번에는 부산이다!!편</p>
                    <p class="tag">#부산에 머가 생겼다고? #해운대점 상담문의 쓰나미!</p>
                    <button data-src="https://www.youtube.com/embed/BS-kZspzJUc" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/BS-kZspzJUc/maxresdefault.jpg" alt="스크린샷">
					</button>
                </li>
                <li class="item_video">
                    <p class="tit">상담매니저 일일 체험기 편</p>
                    <p class="tag">#늬들이 보험클리닉 상담매니저를 알아?</p>
                    <button data-src="https://www.youtube.com/embed/KLBQYbLpeFw" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/KLBQYbLpeFw/maxresdefault.jpg" alt="스크린샷">
					</button>
                </li>
            </ul>
        </div>
    </section> -->
    <!-- //상담매니저 이야기 -->

    <!-- 스토리
    <section id="story" class="section">
        <div class="inner">
            <h3 class="tit_section">
                <p class="tit">일반 보험영업과 무엇이 다른가요?</p>
                <p class="desc">보험클리닉 매니저는 대한민국 전역에 위치한 매장(Shop)에 상주하며,  직접 보험의<br> 필요성을 느껴 방문한 고객을 대상으로 보험상담 및 비교컨설팅을 제공합니다.<br> 또한, 일정수준의 월 수입이 보장되는 급여체계로 보다 안정적인 영업입니다.</p>
            </h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/EnOMcY6vAuE" data-title="보클 상담매니저 3인의 생생 인터뷰" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="/img/recruit/bohum/story_thumb.jpg" alt="스크린샷">
            </button>
        </div>
    </section>
    //스토리 -->

    <!-- 체험기
    <section id="experience" class="section">
        <div class="inner">
            <div class="box_experience">
                <p class="headline">보험클리닉 유튜브</p>
                <p class="tit">상담매니저 일일 체험기</p>
                <p class="desc">고객 발굴에 지친 영업사원들이여~ 이제는 찾지 말고, 맞이 하자!<br> 안정적인 기본급에 실적 인센티브! 쾌적한 근무환경까지. </p>
            </div>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/nsjj0fhNDIg" data-title="상담매니저 일일 체험기" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="/img/recruit/bohum/experience_thumb.jpg" alt="스크린샷">
            </button>
        </div>
    </section>
    //체험기 -->

    <!-- 복리후생
    <section id="welfare" class="section">
        <div class="inner">
            <ul class="list_welfare">
                <li class="item_welfare">
                    <p class="tit_list">복리후생</p>
                    <p class="desc_list">보험클리닉 상담매니저가 서비스 제공에 집중할<br> 수 있도록 쾌적하고 든든한 근무환경을 제공합니다.</p>
                </li>
                <li class="item_welfare"><p class="tit_item">４대 보험 및 퇴직금</p ></li>
                <li class="item_welfare"><p class="tit_item">연차<br> 근로기준법에 의한<br> 근속 기간별 추가 휴가 제공</p ></li>
                <li class="item_welfare"><p class="tit_item">자유로운<br> 연차 사용 권장</p ></li>
                <li class="item_welfare"><p class="tit_item">경조사 휴가/<br> 경조금/화환 지급 </p ></li>
                <li class="item_welfare"><p class="tit_item">출산휴가/육아휴직</p ></li>
                <li class="item_welfare"><p class="tit_item">PC 오프제<br> 근무시간 이후 자동PC 종료</p ></li>
                <li class="item_welfare"><p class="tit_item">쾌적한 환경<br> 최신 인테리어, 지하철 근거리</p ></li>
                <li class="item_welfare"><p class="tit_item">업무용 법인카드 지급</p ></li>
            </ul>
        </div>
    </section>
    //복리후생 -->

    <!-- 복리후생 -->
    <!-- <section id="welfare_v2" class="section">
        <div class="inner">
            <h3 class="tit_section">복리후생</h3>
            <div class="clearfix icons_wrap icons_wrap--first">
                <div class="icons">
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare4.png" class="icon__img" alt="인센티브 + α">
                        <p>인센티브 + α</p>
                    </div>
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare11.png" class="icon__img" alt="명절상여금 (연 2회)">
                        <p>경조사비</p>
                    </div>
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare8.png" class="icon__img" alt="STAFF 연수 프로그램">
                        <p>연수 프로그램</p>
                    </div>
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare10.png" class="icon__img" alt="쾌적한 근무환경">
                        <p>쾌적한<br>근무환경</p>
                    </div>
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare14.png" class="icon__img" alt="업무시간 탄력근무제">
                        <p>업무시간<br>탄력근무제</p>
                    </div>
                    <div class="icon">
                        <img src="/img/recruit/bohum/ico_welfare13.png" class="icon__img" alt="점심시간 자율제">
                        <p>점심시간<br>자율제</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- //복리후생 -->

    <!-- 보험샵이란 -->
    <section id="isshop" class="section">
        <div class="inner">
            <h3 class="tit_section">보험샵(Shop)이란?</h3>
            <ul class="list_isshop">
                <li class="item_isshop">
                    <img class="thumb" src="/img/recruit/bohum/isshop_thumb1.jpg?v=230327" alt="매장내부">
                    <p class="tit">늘 같은 자리에 있는 우리 동네<br>보험전문가</p>
                    <p class="desc">보험클리닉 상담매니저가 매장에 상주하며, <strong>스스로 보험의 필요성을 느껴 방문한 고객에게 서비스를 제공하는 비즈니스 모델</strong>입니다.<br><br> 특히 한 자리에 점포 형태로 영업을 하기에 지속적인 고객관리가 가능하며, 국내 주요 보험사와의 제휴를 통해 모든 보험에 대한 비교 분석을 제공할 수 있습니다.</p>
                </li>
                <!-- <li class="item_isshop">
                    <img class="thumb" src="/img/recruit/bohum/isshop_thumb2.png" alt="화살표">
                    <p class="tit">가파르게 성장중인<br>新 비즈니스 모델</p>
                    <p class="desc">실제로 고령화, 1인 가구 등 사회변화를 먼저 겪은 일본의 경우<br>2019년 현재 기준, 전체 보험가입의 30%가 내방형 점포에서 발생하고<br>있습니다.</p>
                </li> -->
            </ul>
        </div>
    </section>
    <!-- //보험샵이란 -->

    <!-- 전국매장 -->
    <section id="allshop" class="section">
        <div class="inner">
            <h3 class="tit_section">대한민국 보험의 새로운 역사, 보험클리닉으로부터</h3>
            <p class="desc_section">번화가 상권을 중심으로 지속 확장</p>
            <div class="map">
                <i class="seoul">서울</i>
                <i class="incheon">인천</i>
                <i class="gyeonggi">경기</i>
                <i class="gangwon">강원</i>
                <i class="chungnam">충남</i>
                <i class="chungbuk">충북</i>
                <i class="daejeon">대전</i>
                <i class="gyeongbuk">경북</i>
                <i class="daegu">대구</i>
                <i class="jeonbuk">전북</i>
                <i class="gwangju">광주</i>
                <i class="gyeongnam">경남</i>
                <i class="ulsan">울산</i>
                <i class="busan">부산</i>
                <i class="jeonnam">전남</i>
                <i class="jeju">제주</i>
                <img src="/img/recruit/bohum/allshop_map.png" alt="전국지도">
            </div>
            <p class="guide_text">※ 자세한 위치는 보험클리닉 홈페이지 <a href="https://bohumclinic.com/shop/find/" target="_blank">‘보험샵찾기’</a> 를 참고하세요</p>
            <div class="door">
                <img src="/img/recruit/bohum/allshop_door.jpg" alt="매장입구">
            </div>
            <div class="swiper">
                <p class="tit_swiper">전국 어디서나<br><strong>보험클리닉</strong></p>
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg1.jpg)"><p class="tit">서울 이수점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg2.jpg)"><p class="tit">서울 상일점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg4.jpg)"><p class="tit">경기 안양점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg5.jpg)"><p class="tit">인천 부평역점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg6.jpg)"><p class="tit">광주 상무점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg7.jpg)"><p class="tit">경기 정발산점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg9.jpg)"><p class="tit">경기 판교점</p></div>
                        <div class="swiper-slide" style="background-image:url(/img/recruit/bohum/allshop_slide_bg10.png)"><p class="tit">부산 해운대장산점</p></div>
                    </div>
                </div>
                <!-- Add Navigation -->
                <div class="swiper-button">
                    <button class="swiper-button-prev" type="button"><img src="" data-images-path="/image/svg/prev01.svg" alt="뒤로"></button>
                    <button class="swiper-button-next" type="button"><img src="" data-images-path="/image/svg/next01.svg" alt="앞으로"></button>
                </div>
            </div>
        </div>
    </section>
    <!-- //전국매장 -->

    <!-- 서비스 -->
    <section id="service" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 3대 서비스</h3>
            <p class="desc_section">보험 고민 해결부터 사후관리까지! 상담매니저의 1:1 맞춤 컨설팅</p>
            <ul class="list_service">
                <li class="item_service">
                    <p class="tit">보험<br>진단</p>
                    <p class="desc">자체적으로 개발한 지능형보장분석시스템인<br> @ssist를 활용하여 보유중인 보험을 진단하는<br> 맞춤형 리모델링을 제공하고 있습니다.</p>
                </li>
                <li class="item_service">
                    <p class="tit">비교<br>추천</p>
                    <p class="desc">하나의 보험사 상품만 제안할 수 있는 전속대리점과<br> 달리 보험클리닉은 국내 존재하는 수많은<br> 보험상품의 비교와 설계가 가능합니다. </p>
                </li>
                <li class="item_service">
                    <p class="tit">보상<br>청구</p>
                    <p class="desc">사고가 나거나 수술 직후 몸을 움직이<br> 힘든 상황에서 직접 보험금 청구가 힘든 고객을<br> 위한 보상청구 서비스를 운영하고 있습니다. </p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //서비스 -->

    <!-- 채용절차 -->
    <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 상담매니저 채용절차</h3>
            <p class="desc_section">서류전형부터 면접까지! 보험클리닉 입사에 대한 모든 것</p>
            <ol class="list_process">
                <li class="item_process">
                    <p class="tit">서류전형</p>
                    <p class="cmt">* 서류 통과 시 개별 통보됩니다.</p>
                </li>
                <li class="item_process">
                    <p class="tit">1차 실무진 면접</p>
                </li>
                <li class="item_process">
                    <p class="tit">2차 임원면접</p>
                </li>
                <li class="item_process">
                    <p class="tit">최종합격</p>
                </li>
            </ol>
            <!-- 모집부분 및 채용규모 -->
            <div class="table mb-60">
                <table summary="직군별 채용안내에 대한 내용을 확인 할 수 있습니다.">
                    <caption>모집부분 및 채용규모</caption>
                    <colgroup>
                        <col style="width:15%;">
                        <col style="width:*;">
                        <col style="width:15%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>모집분야</th>
                            <th>주요업무</th>
                            <th>채용규모</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="align-center">보험클리닉<br>상담매니저</th>
                            <td>
                                ·보험비교분석 시스템을 활용한 보험 분석 및 대안 제시<br>
                                ·고객응대 및 고객상담을 통한 영업
                            </td>
                            <td class="align-center">00명</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 전형별 일정 -->
            <div class="table mb-60">
                <table>
                    <caption>전형별 일정</caption>
                    <colgroup>
                        <col style="width:20%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>주요내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>서류전형</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>면접일정</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>인턴과정</th>
                            <td>상시변경</td>
                        </tr>
                    </tbody>
                </table> 
                <!-- <div class="recruit_close"><img src="/img/recruit/bohum/pc_recruit_end.png" alt="채용마감"></div> -->
            </div>
            <!-- 지원방법 -->
            <div class="table mb-60">
                <table>
                    <caption>지원방법</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="mb-10"><strong>사람인 즉시지원 또는 자사이력서 작성 후 이메일 제출 → <a href="mailto:bohumclinic@peoplelife.co.kr">bohumclinic@peoplelife.co.kr</a></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 고용형태 및 근무조건 -->
            <div class="table mb-60">
                <table>
                    <caption>고용형태 및 근무조건</caption>
                    <colgroup>
                        <col style="width:20%;">
                        <col style="width:*;">
                        <col style="width:*;">
                    </colgroup>
                    <thead> 
                        <tr>
                            <th>구분</th>
                            <th>주요내용</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>고용형태</th>
                            <td>
                                <ul>
                                    <li>위촉직</li>
                                    <!-- <li>인턴과정 수료 (수습평가 기간 6개월) </li> -->
                                </ul>
                            </td>
                            <td> - </td>
                        </tr>
                        <tr>
                            <th>근무시간</th>
                            <td>
                                10:00 ~ 19:00시 근무
                            </td>
                            <td>
                                <ul>
                                    <li>- 일요일 및 공휴일 휴무</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>연봉</th>
                            <td>
                                <ul>
                                    <li>별도 문의</li>
                                </ul>
                            </td>
                            <td> - </td>
                        </tr>
                        <tr>
                            <th>채용지역</th>
                            <td>
                                <ul>
                                    <li>전국</li>
                                </ul>
                            </td>
                            <td class="new"> - </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 지원자격 및 우대사항 -->
            <div class="table mb-60">
                <table>
                    <caption>지원자격 및 우대사항</caption>
                    <colgroup>
                        <col style="width:20%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead> 
                        <tr>
                            <th>구분</th>
                            <th>주요내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>지원자격</th>
                            <td>
                                <dl class="list_bullet">
                                    <dd>보험 영업직 경력 1년 이상</dd>
                                    <dd>초대졸 이상</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <th>우대사항</th>
                            <td>
                                <dl class="list_bullet">
                                    <dd>AFPK, CFP등 금융자격증 보유자</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <th>유의사항</th>
                            <td>
                                <dl class="list_bullet">
                                    <dt>[ 채용제한 ]</dt>
                                    <dd>신용등급 8등급 이하(점수 534점)</dd>
                                    <dd>보험회사 3년내 이직횟수 3회 이상자</dd>
                                    <dd>보증보험 가입 불가자</dd>
                                    <dd>지원서 내용이 사실과 다르거나 문서로 증빙이 불가능할 경우</dd>
                                </dl>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 지원방법 -->
            <div class="table">
                <table>
                    <caption>접수기간</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="txt_dday">상시채용</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="recruit_close"><img src="/img/recruit/bohum/pc_recruit_end.png" alt="채용마감"></div> -->
            </div>
        </div>
    </section>
    <!-- //채용절차 -->

    <!-- 방문상담신청 -->
    <section id="applyForm"  class="section" data-recruit-state=""> <!-- 종료시 data-recruit-state="deadline" -->
        <div class="inner">
            <h3 class="tit_section">보험클리닉 상담매니저 지원하기</h3>
            <li class="row row_carrer_life row_css" data-check="false" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check="false" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check="false" data-check-pass="true" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="loadForm"></div>
            <div class="box_info">
                <p class="tit">보험클리닉 <strong>입사지원서 다운받기</strong></p>
                <p class="desc">입사지원서를 작성하신 후 제출해주시기 바랍니다.<br><span>※ 다른 양식의 입사지원서로도 지원 가능합니다. (잡코리아, 사람인 이력서도 제출 가능)</span></p>
                <a class="link_download" href="" data-images-path="/data/doc/bohumclinic_resume.docx" download><span>입사지원서 다운로드</span></a>
            </div>
        </div>
    </section>
    <!-- //방문상담신청 -->

    <!-- FAQ -->
    <section id="faq" class="section">
        <div class="inner">
            <h3 class="tit_section">FAQ</h3>
            <ul class="list_faq">
                <li class="item_faq">
                    <div class="question">주요 업무가 무엇인가요?</div>
                    <div class="answer">피플라이프가 자체 개발한 지능형보장분석시스템을 통해 기존 보험의 과다·부족 여부를 진단하는 컨설팅을 진행하며,<br> 보험금 청구 대행, 숨은 보험금 찾기 등 기타 서비스를 안내합니다. </div>
                </li>
                <li class="item_faq">
                    <div class="question">보험을 판매해야 하나요?</div>
                    <div class="answer">보험대리점의 주 수입원은 보험계약이며, 보험클리닉 또한 보험 판매를 통해 운영됩니다.</div>
                </li>
                <li class="item_faq">
                    <div class="question">입사 후 과정은 어떻게 진행되나요?</div>
                    <div class="answer">신입 교육 및 현장 OJT를 거쳐 매니저의 거주지 등을 고려하여 배치합니다. </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- //FAQ -->

    <!-- 채용문의 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="txt1">새로운 Role Model의 주인공이 되고 싶다면?</p>
            <h3 class="txt2">지금 바로. 잡으세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <em class="txt3">※ 본 화면은 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</em>
            <div class="box_about">
                <p class="tit">채용문의</p>
                <p class="tel">02-2251-6862</p>
                <p class="mail">bohumclinic@peoplelife.co.kr</p>
                <p class="cmt">평일 09:00~18:30 근무시간 외 E-mail 문의</p>
            </div>
        </div>
    </section>
    <!-- //채용문의 -->

    <!-- 채용설명회  floating -->
    <aside id="banner_recruitEmail" hidden data-recruit-state=""> <!-- 종료시 data-recruit-state="deadline" -->
        <a class="link_more" href="http://asq.kr/7fUZxyrTwjml" target="_blank">
            <img src="/img/recruit/bohum/banner_recruit_pc_2109_2.png?v=2109" alt="보험클리닉 상담매니저 온라인 라이브 채용설명회 2021.09.13(월) 14:00">
        </a>
        <div class="email_banner">
            <div class="top">
                <div class="title">입사지원 방법</div>
                <p class="end-time">
                    공개채용이<br> 
                    마감되었습니다.<br>
                    <a href="mailto:bohumclinic@peoplelife.co.kr">bohumclinic@peoplelife.co.kr</a>
                </p>
            </div>
            <div class="bottom">
                <a href="/file/bohumclinic_resume.docx" download="보험클리닉_자사양식 이력서">자사양식 다운로드</a>
                <i></i>
            </div>
        </div>
    </aside>
    <!-- //채용설명회 floating -->

    <!-- 입사문의 바로가기 floating -->
    <aside id="banner_apply">
        <div class="inner">
            <button class="btn_apply anchor" data-target="#applyForm" data-recruit-state="" type="button"> <!-- 종료시 data-recruit-state="deadline" --> <!--  -->
                <span class="screen_out">입사문의 바로가기</span>
            </button>
        </div>
    </aside>
    <!-- //입사문의 바로가기 floating -->
</main>
<!-- /main -->
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitBohum.scss';
</style>

<script>
    export default {}
</script>
<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <div class="txt">
                <em>TFA</em>
                <strong>실시간 보험 비교 서비스로<br> 라이프 리스크 간편 대비,<br> TFA</strong>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section id="information" class="section">
        <div class="inner">
            <h3 class="tit_section"><img src="/img/sales/tfa/information_title.png" alt="FA"></h3>
            <div class="thumb">
                <img src="/img/sales/tfa/information_thumb.jpg" alt="사진">
            </div>
            <div class="info">
                <p class="txt-top">비교, 설계부터 관리까지!<br> 고객맞춤 보험 서비스<br><strong>「 TFA 」</strong></p>
                <p class="txt-bottom">24시간 실시간으로 언제나, 어디서나! 시간과 장소에 구애없이<br> 클릭 한 번으로 암, 치매, 치아 같은 건강 문제부터 운전자, 주택화재 까지 <br>다양한 리스크의 맞춤형 상품의 월 보험료를 확인할 수 있습니다. <br><br> 고객의 편의성을 최우선으로 생각하여 보장은 빠짐없이 보험료는 저렴하게!<br> 고객편의 맞춤형 생활보장 안심케어를 제공합니다. </p>
            </div>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 서비스 -->
    <section id="service" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프 TM영업만의 차별화 서비스</h3>
            <ul class="list_service">
                <li class="item_service">                    
                    <img class="thumb" src="/img/sales/tfa/service_thumb1.jpg" alt="사진">
                    <div class="info">
                        <p class="num">1</p>
                        <p class="tit">비대면 텔레마케팅 서비스!</p>
                        <p class="desc">날짜, 장소, 시간에 구애 없이!<br> 언제나, 어디서나! 고객님이 원하는 시간에 맞춰<br> 보험에 관한 궁금증을 완벽하게 해결할 수 있습니다. </p>
                    </div>
                </li>
                <li class="item_service">                    
                    <img class="thumb" src="/img/sales/tfa/service_thumb2.jpg" alt="사진">
                    <div class="info">
                        <p class="num">2</p>
                        <p class="tit">실시간 보험 비교 서비스!</p>
                        <p class="desc">국내 주요 보험사 상품을 나이, 성별, 필요한 보장 등을 <br> 고려한 보험료 계산 시스템을 통해 <br> 고객님의 예상 월 보험료를 24시간 실시간으로 확인할 수 있습니다.</p>
                    </div>
                </li>
                <li class="item_service">                    
                    <img class="thumb" src="/img/sales/tfa/service_thumb3.jpg" alt="사진">
                    <div class="info">
                        <p class="num">3</p>
                        <p class="tit">고객 만족형 상담 진행!</p>
                        <p class="desc">기존 보험 점검, 리모델링을 위한 상담 비용 ZERO!<br>보험 전문 상담사가 가입된 보장을 꼼꼼히 살펴본 후<br> 보험을 잘 가입할 수 있게 도와드립니다.</p>
                    </div>
                </li>
                <li class="item_service">                    
                    <img class="thumb" src="/img/sales/tfa/service_thumb4.jpg" alt="사진">
                    <div class="info">
                        <p class="num">4</p>
                        <p class="tit">가입 후 평생 관리 시스템!</p>
                        <p class="desc">보험 전문가가 보험점검부터 가입 후 관리까지! <br> 가입 이후에도 보험에 대해 정보변경이나 보험금 청구,<br> 라이프 플랜에 따른 보장의 재검토 필요 시<br> 고객님의 보험을 평생 책임집니다. </p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- 서비스 -->

    <!-- 프로세스 -->
    <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">고객 맞춤형 컨설팅 프로세스</h3>
            <ul class="list_process">
                <li class="item_process">
                    <img class="thumb" src="/img/sales/tfa/process_thumb1.jpg" alt="사진">
                    <div class="info">
                        <p class="tit"><strong>STEP 1.</strong> 보험료 계산 & 상품비교</p>
                        <p class="desc">국내 주요 보험사 상품을<br> 고객 나이, 성별, 필요한 보장 등을 분석하여<br> 맞춤 설계를  진행합니다. </p>
                        <p class="num">STEP 1</p>
                    </div>
                </li>
                <li class="item_process">
                    <img class="thumb" src="/img/sales/tfa/process_thumb2.jpg" alt="사진">
                    <div class="info">
                        <p class="tit"><strong>STEP 2.</strong> 무료상담 진행</p>
                        <p class="desc">보험 전문가가 고객님의 정보 및 선택 상품을 바탕으로<br> 무료 전화상담을 진행합니다.<br> 궁금한 점은 무엇이든 물어보세요! </p>
                        <p class="num">STEP 2</p>
                    </div>
                </li>
                <li class="item_process">
                    <img class="thumb" src="/img/sales/tfa/process_thumb3.jpg" alt="사진">
                    <div class="info">
                        <p class="tit"><strong>STEP 3.</strong> 가입 후 평생 관리</p>
                        <p class="desc"><br>가입 이후에도 가입 보험에 대해 정보변경이나 보험금 청구,<br> 라이프 플랜에 따라 보장의 재검토 등 필요 시 관리해드려요. </p>
                        <p class="num">STEP 3</p>
                    </div>
                </li>                
            </ul>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //프로세스 -->

    <!-- 솔루션 -->
    <section id="solution" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프만의 보험 솔루션 시스템</h3>
            <ul class="list_solution">
                <li class="item_solution">
                    <p class="tit">생활보장 안심케어</p>
                    <p class="desc">평균 경력 10년 이상의<br> 전문TFA가 설계 </p>
                </li>
                <li class="item_solution">
                    <p class="tit">비대면 고객정보 수집</p>
                    <p class="desc">전화, 이메일, 메시지, 카카오톡 외<br> 고객소통수단 활용</p>
                </li>
                <li class="item_solution">
                    <p class="tit">상품분석 알고리즘</p>
                    <p class="desc">T-System을 통한 고객정보 암호화 및<br> 최적보장분석프로그램을<br> 통한 상품비교분석</p>
                </li>
                <li class="item_solution">
                    <p class="tit">LIFE RISK 대비 </p>
                    <p class="desc">건강, 치매, 운전자보험, 주택화재 등<br> 다양한 생활리스크 대비 </p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //솔루션 -->    

    <!-- 상품제휴 -->
    <section id="alliance" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프 상품 제휴사</h3>
            <div class="list_alliance life">
                <p class="tit_list"><strong>생명보험사 제휴</strong>국내 주요 생명보험사와 상품판매 업무제휴를 맺어 고객중심 맞품형 상품을 제안합니다.</p>
                <div class="swiper-container">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide"><img src="" alt="로고"></li>
                    </ul>
                    <!-- Add Navigation -->
                    <button class="swiper-button-prev swiper-button-black" type="button"></button>
                    <button class="swiper-button-next swiper-button-black" type="button"></button>
                </div>
            </div>
            <div class="list_alliance damage">
                <p class="tit_list"><strong>손해보험사 제휴</strong>국내 주요 손해보험사와 상품판매 업무제휴를 맺어 화재보험 전문가를 통한 종합관리 및 손해보험사 상품 비교를 통해 효율적인 방법을 제안합니다.</p>
                <div class="swiper-container">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide"><img src="" alt="로고"></li>
                    </ul>
                    <!-- Add Navigation -->
                    <button class="swiper-button-prev swiper-button-black" type="button"></button>
                    <button class="swiper-button-next swiper-button-black" type="button"></button>
                </div>
            </div>
        </div>
    </section>
    <!-- //상품제휴 -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <h3 class="tit_section">실시간 보험료 비교로<br> 슬기로운 보험 생활</h3>
            <p class="desc_section">국내 주요 보험사 상품을 언제, 어디서나! 24시간, 365일! <br> 실시간으로 보험료를 확인하고 고객님께 가장 적합한 상품을 확인해보세요!</p>
            <div class="list_tfa">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <img class="line" src="/img/sales/tfa/about_tfa_line.svg" alt="">
                <img class="bg" src="/img/sales/tfa/about_tfa_bg.png" alt="">
            </div>
        </div>
        <div class="illust left">
            <img src="/img/sales/tfa/about_bg_illust1.png" alt="일러스트">
        </div>
        <div class="illust right">
            <img src="/img/sales/tfa/about_bg_illust1.png" alt="일러스트">
        </div>
    </section>
    <!-- //안내사항 -->


</main>
<!-- /main -->
</template>



<script>
export default {}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/tfa.scss';
</style>

<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <div class="txt">
                <em><img src="" data-images-path="/image/logo/bohumclinic_extension01.svg"></em>
                <strong>고객이 직접<br> 방문하는 ‘보험클리닉’<br> 오프라인 보험샵 </strong>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section id="information" class="section">
        <div class="inner">
            <h3 class="tit_section"><img :src="imgPath+'information_title.png'" alt="FA"></h3>
            <div class="thumb">
                <img :src="imgPath+'information_thumb.jpg?v=221124'" alt="사진">
            </div>
            <div class="info">
                <p class="txt-top">고객님과 가장 가까운 곳에서<br> 보험을 바로. 잡아드립니다.</p>
                <p class="txt-bottom">보험도 마트나 백화점에서 쇼핑하듯이 상황과 니즈에 맞게 보험상품을<br> 능동적으로 쇼핑하는 시대입니다. 고객의 편의성을 최우선적으로 고려하는<br>보험클리닉은 APP·인터넷(웹)·모바일(전화)를 활용하여 방문 예약할 수 있습니다.<br>전국 주요도시의 보험샵에서 직접 경험해보세요. </p>
                <a class="link_more" href="https://bohumclinic.com/shop" target="_blank"><span>보험클리닉 SHOP 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
            </div>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 인재지원 -->
    <section id="people" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 3대 차별화 서비스</h3>
            <ul class="list_people">
                <li class="item_people">
                    <p class="tit">보험진단 서비스</p>
                    <p class="desc">자체 개발한 보험클리닉App으로<br> 보유보험 적정성 진단</p>
                </li>
                <li class="item_people">
                    <p class="tit">비교추천 서비스</p>
                    <p class="desc">국내 주요 보험사와 제휴하여 다수 상품 中<br> 고객이 직접선택</p>
                </li>
                <li class="item_people">
                    <p class="tit">보험금 청구 대행 서비스</p>
                    <p class="desc">가입한 보험사 상관없이<br> 무료 보험금 청구 대행 서비스</p>
                </li>
            </ul>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //인재지원 -->

    <!-- 숫자로 보는 보험클리닉 -->
    <section id="viewed" class="section">
        <div class="inner">
            <h3 class="tit_section">숫자로 보는 보험클리닉</h3>
            <div class="swiper-container swiper-thumb">
                <p class="tit_container"></p>
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_thumb1.jpg?v=221124)'"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_thumb2.jpg)'"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_thumb3.jpg)'"></div>
                    </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_top1.jpg?v=221129)'"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_top2.jpg)'"></div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slide-inner" :style="'background-image:url('+imgPath+'viewed_top3.jpg)'"></div>
                    </div>
                </div>
            </div>
            <div class="swiper-container swiper-bottom">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p class="desc">2019년 4월 Grand Open 이후</p>
                        <p class="tit">누적 방문자 수</p>
                        <p class="count"><span class="num"></span><span class="unit">명</span></p>
                        <p class="source"><span class="date"></span>(자사 방문상담 데이터 기준)</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="desc">2019년 4월 Grand Open 이후</p>
                        <p class="tit">보험클리닉 SHOP 지점 수</p>
                        <p class="count"><span class="num"></span><span class="unit">개</span></p>
                        <p class="source"><span class="date"></span>(자사 보험 SHOP점포 데이터 기준)</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="desc">보험클리닉 SHOP 홈페이지 </p>
                        <p class="tit">상담 예약 수 </p>
                        <p class="count"><span class="num"></span><span class="unit">건</span></p>
                        <p class="source"><span class="date"></span>(자사 상담예약 데이터 기준)</p>
                    </div>
                </div>
            </div>
            <div class="swiper-nav">
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button-black" type="button"></button>
                <button class="swiper-button-next swiper-button-black" type="button"></button>
            </div>
        </div>
    </section>
    <!-- //숫자로 보는 보험클리닉 -->

    <!-- TVCF -->
    <videoSwiper01 />
    <!-- //TVCF -->

    <!-- 라이프 케어 -->
    <section id="lifecare" class="section">
        <div class="inner">
            <h3 class="tit_section">생애 전반의 라이프 케어 컨설팅을 보험클리닉과 한번에!</h3>
            <p class="desc_section">보험클리닉에 방문하시면  다양한 보험상담 솔루션 제공이 가능합니다.</p>
            <div class="left">
                <p class="tit">보험클리닉만의<br> <strong>'보클 처방전'</strong>으로<br> 믿을 수 있는 <em>보험클리닉 상담매니저가</em><br> 보험을 무료로 <em>진단</em>해드립니다.</p>
                <img class="thumb" :src="imgPath+'lifecare_left_book.png?v=0233271'" alt="썸네일">
            </div>
            <div class="right">
                <div class="tab-container">
                    <nav class="tab-nav">
                        <button class="active" data-tab="tab-1" type=button>사망<i></i></button>
                        <button data-tab="tab-2" type=button>건강<i></i></button>
                        <button data-tab="tab-3" type=button>어린이<i></i></button>
                        <button data-tab="tab-4" type=button>치매&#183;간병<i></i></button>
                        <button data-tab="tab-5" type=button>일상생활<i></i></button>
                        <button data-tab="tab-6" type=button>자녀자금<i></i></button>
                        <button data-tab="tab-7" type=button>노후자금<i></i></button>
                        <button data-tab="tab-8" type=button>증여&#183;상속<i></i></button>
                    </nav>

                    <div class="tab-wrapper">
                        <div id="tab-1" class="tab-slide active">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb1.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">사망 보장 솔루션</p>
                                <p class="desc">주 수입원이나 남편이 조기 사망할 경우 장례비, 사망정리자금과 남겨진 가족에게 필요한 생활자금, 주택자금, 자녀양육자금 등을 준비</p>
                            </div>
                        </div>
                        <div id="tab-2" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb2.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">건강 보장 솔루션 </p>
                                <p class="desc">질병과 상해로 지출될 병원비를 대비하여 암, 뇌혈관, 심혈관, 입원, 수술 그리고 실손 보장이 필요 </p>
                            </div>
                        </div>
                        <div id="tab-3" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb3.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">어린이 보장 솔루션</p>
                                <p class="desc">신생아보험, 어린이보험 등 어릴때 부터 미리 100세 시대를 대비한 보험을 준비 </p>
                            </div>
                        </div>
                        <div id="tab-4" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb4.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">치매·간병 보장 솔루션</p>
                                <p class="desc">장해로 인한 소득 중단과 어느 가정에서나 일어날 수 잇는 치매를 대비할 수 있는 자금 </p>
                            </div>
                        </div>
                        <div id="tab-5" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb5.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">일상생활 보장 솔루션</p>
                                <p class="desc">운전자보험, 치아보험, 화재보험 등 일상생활 중 필요한 보장을 준비 </p>
                            </div>
                        </div>
                        <div id="tab-6" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb6.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">자녀자금 보장 솔루션 </p>
                                <p class="desc">자녀의 대학 및 대학원 등록비, 유학자금, 그리고 결혼자금 준비 </p>
                            </div>
                        </div>
                        <div id="tab-7" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb7.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">노후자금 보장 솔루션 </p>
                                <p class="desc">기대수명 100세 시대에 보다 풍요롭고 여유로운 노후를 위한 자금 준비 </p>
                            </div>
                        </div>
                        <div id="tab-8" class="tab-slide">
                            <img class="thumb" :src="imgPath+'lifecare_right_thumb8.jpg'" alt="썸네일">
                            <div class="box">
                                <p class="tit">증여·상속 보장 솔루션</p>
                                <p class="desc">계획적인 증여와 상속은 바로 해결해야 되는 문제</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //라이프 케어 -->

    <!-- 컨설팅 프로세스 -->
    <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 상담 프로세스</h3>
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">1</span>STEP</p>
                            <p class="tit">보험문진</p>
                            <p class="desc">
                                <span>상담신청 및 안내</span>
                                <span>기초 문진</span>
                                <span>어플리케이션 설치 및 보험 파악</span>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">2</span>STEP</p>
                            <p class="tit">보험진단</p>
                            <p class="desc">
                                <span>보험증권 파악 및 보장 분석</span>
                                <span>보험 목적별 협의</span>
                                <span>협의안 도출</span>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">3</span>STEP</p>
                            <p class="tit">보험처방</p>
                            <p class="desc">
                                <span>솔루션 제시</span>
                                <span>솔루션 실행</span>
                                <span>금융상품 비교추천</span>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">4</span>STEP</p>
                            <p class="tit">보험관리</p>
                            <p class="desc">
                                <span>보상청구 서비스</span>
                                <span>일상적인 서비스 및 이벤트 안내</span>
                                <span>고객이 필요 시 언제든 보험샵 내방</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb1_1.jpg')"></div>
                    <div class="swiper-slide" :style="'background-image:url('+imgPath+'process_thumb2.jpg)'"></div>
                    <div class="swiper-slide" :style="'background-image:url('+imgPath+'process_thumb3.jpg?v=221124)'"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb7_1.jpg')"></div>
                </div>
            </div>

            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_left01.svg" alt="뒤로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></button>
            </div>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //컨설팅 프로세스 -->

    <!-- 방문 및 상담신청 -->
    <section id="visit" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉을 만나는 쉽고 빠른 방법</h3>
            <ul class="list_visit">
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">홈페이지 상담신청</p>
                    <p class="desc">보험클리닉 홈페이지에서<br> 가까운 보험샵에 신청하세요!</p>
                    <a class="link_more" href="//bohumclinic.com/shop/helpdesk/quickconsult/" target="_blank"><span>방문신청 하기</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
                </li>
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">직접방문 </p>
                    <p class="desc">가까운 보험샵을 찾아<br> 바로 방문하세요.</p>
                    <a class="link_more" href="//bohumclinic.com/shop/find" target="_blank"><span>보험샵 찾기</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
                </li>
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">전화 상담신청</p>
                    <p class="desc">1661-5085에서<br> 상담신청을 도와드려요.</p>
                </li>
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">보험클리닉 APP </p>
                    <p class="desc">손 안에서 보험클리닉<br> 서비스를 경험해보세요.</p>
                </li>
            </ul>
        </div>
    </section>    
    <!-- //방문 및 상담신청 -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">새로운 Role Model의 주인공이 되고 싶다면? </p>
            <h3 class="tit_section">지금 바로. 잡으세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <a class="link_about" href="/recruit/RecruitBohum"><span>보험클리닉 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <img class="model" :src="imgPath+'about_model.png'" alt="모델">
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>


 
<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/otc/'
        }
    }
}
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/sales/otc.scss';
</style>
